import { Box, IconButton, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import "./SliderWithArrows.scss";

export const SliderWithArrows = ({ slides, firstSlide, maxWidth }) => {
  const [currentSlide, setCurrentSlide] = useState(firstSlide);
  const [loading, setLoading] = useState([]);
  const [clientXStart, setClientXStart] = useState(null);
  const [clientXEnd, setClientXEnd] = useState(null);

  const sliderLength = slides.length;

  const prevSlide = () => {
    if (currentSlide !== 1) {
      setCurrentSlide((prevState) => prevState - 1);
    }
  };

  const nextSlide = () => {
    if (currentSlide !== slides.length) {
      setCurrentSlide((prevState) => prevState + 1);
    }
  };

  return (
    <div className="sliderWithArrows">
      <IconButton
        className="sliderWithArrows__nav sliderWithArrows__prev"
        onClick={prevSlide}
        disabled={currentSlide === 1}
      >
        <ChevronLeft />
      </IconButton>
      {loading.length !== sliderLength && <CircularProgress />}
      <Box
        className={`sliderWithArrows__slides${loading.length !== sliderLength ? " invisible" : ""}`}
        css={{ maxWidth }}
      >
        {slides.map((slide, i) => (
          <img
            onTouchStart={(e) => setClientXStart(e.touches[0].clientX)}
            onTouchEnd={(e) => {
              setClientXEnd(e.changedTouches[0].clientX);
              clientXStart < clientXEnd ? prevSlide() : nextSlide();
            }}
            onLoad={() => setLoading((prevState) => [...prevState, slide.src])}
            key={slide.src}
            className={`sliderWithArrows__slide${currentSlide === i + 1 ? " active" : ""}`}
            src={slide.src}
            alt=""
          />
        ))}
      </Box>
      <IconButton
        className="sliderWithArrows__nav sliderWithArrows__next"
        onClick={nextSlide}
        disabled={currentSlide === slides.length}
      >
        <ChevronRight />
      </IconButton>
    </div>
  );
};
