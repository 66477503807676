import { PlayCircleOutline } from "@material-ui/icons";
import React from "react";
import ReactPlayer from "react-player";

export const CustomReactPlayer = ({ src, poster }) => {
  return (
    <ReactPlayer
      style={{ margin: "0 auto 10px", maxWidth: "100%" }}
      playing
      playIcon={<PlayCircleOutline style={{ fontSize: 90, color: "#ef9b0d" }} />}
      url={src}
      light={poster}
      controls
    />
  );
};
