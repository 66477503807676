import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomReactPlayer from "../../CustomReactPlayer";

export const UsingElastics = () => {
  const { t: _t } = useTranslation();
  return (
    <>
      <h2>{_t("Завершающие эластики")}</h2>
      <LazyLoadImage src={require("../../assets/1_using-elastics/1.jpg")} effect="blur" alt="" />
      <h2>{_t("Описание методики:")}</h2>
      <p>
        {_t(
          "Завершающие эластики – межчелюстные тяги, которые при-меняются в конце лечения для улучшения межчелюстных отношений зубов. Данная методика используется в комплексе с элайнерами. Назначаются при не достаточно плотных окклюзионных контактах между зубами после того, как зубные дуги были выровнены. Предназначены для улучшения окклюзионных контактов. Данная методика подразумевает использование латексных эластиков, зафиксированных на ортодонтических кнопках между антагонистами, для обеспечения межчелюстной тяги навстречу друг другу. Фиксацию эластиков необходимо производить при надетых элайнерах. Жёсткость и сроки ношения определяются врачом-ортодонтом.",
        )}
      </p>
      <h2>{_t("Протокол использования завершающих эластиков.")}</h2>
      <p>{_t("В случаях, когда элайнеры изготовлены с учётом использования завершающих эластиков:")}</p>
      <p>{_t("- фиксация элайнеров с вырезами под ортодонтические кнопки на зубы;")}</p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/2.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "- подготовка зубов в области фиксации кнопки в соответствии со стандартным протоколом работы с композитным реставрационным материалом;",
        )}
      </p>
      <p>{_t("- фиксация ортодонтических кнопок в области специальных вырезов на элайнерах;")}</p>

      <LazyLoadImage src={require("../../assets/1_using-elastics/3.jpg")} effect="blur" alt="" />

      <p>
        {_t("- удаление излишков композитного материала и контроль бес-препятственного снятия и надевания элайнеров;")}
      </p>
      <p>{_t("- фиксация латексных эластиков на кнопки по зигзагообразному принципу;")}</p>

      <LazyLoadImage src={require("../../assets/1_using-elastics/4.jpg")} effect="blur" alt="" />

      <p>{_t("- консультация пациента на предмет ношения элайнеров с системой эластиков;")}</p>
      <p>{_t("- контроль процесса снятия и надевания пациентом элайнеров вместе с эластиками.")}</p>
      <p>
        {_t(
          "В случаях, когда система эластиков применяется в середине или в конце лечения, необходимо изготовление вырезов каплевидной формы на верхнем и нижнем элайнере, в области некорректного окклюзионного контакта зубов-антагонистов. При этом диаметр самой широкой части выреза должен соответствовать диаметру основания ортодонтической кнопки. В остальных действиях используется основной протокол. После фиксации кнопок все остальные элайнеры подгоняются при припасовке, если вырезы не были предусмотрены в начале лечения.",
        )}
      </p>

      <h2>{_t("Рекомендации пациентам.")}</h2>

      <p>{_t("Для использования системы элайнеров и завершающих эластиков пациенту необходимо:")}</p>
      <p>
        {_t("- надеть элайнеры на верхнюю и нижнюю челюсть;")} <br />
        {_t("- убедиться в том, что ортодонтические аппараты сели плотно на зубы;")} <br />
        {_t("- взять эластики, рекомендованные врачом – стоматологом ортодонтом;")} <br />
        {_t(
          "- надеть один край эластика на крайний фиксатор и натянуть его на все остальные по зигзагообразному принципу вплоть до последнего;",
        )}
        <br />
        {_t("- использовать рекомендации врача по срокам ношения эластиков и по их замене.")}
      </p>

      <CustomReactPlayer
        src={require("../../assets/1_using-elastics/video_4.mp4")}
        poster={require("../../assets/1_using-elastics/video_4.jpg")}
      />
      <h2>{_t("Эластики для экструзии")}</h2>
      <LazyLoadImage src={require("../../assets/1_using-elastics/5.jpg")} effect="blur" alt="" />
      <h2>{_t("Описание методики:")}</h2>
      <p>
        {_t(
          "Эластики для экструзии – эластические одночелюстные тяги, которые применяются для экструзии ретинированных зубов или зубов с разрушенной коронковой частью. Эластические тяги используются в комплексе с элайнерами для активной экструзии отдельных зубов. Применятся при сильно укороченной коронковой части зуба (более 3 мм). Данная методика подразумевает использование латексных эластиков, зафиксированных на ортодонтических кнопках с одной стороны и верхней части элайнера с другой. Для создания элайнера под данную методику используются специальные вырезы до режущего края желаемой высоты коронки.",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/6.jpg")} effect="blur" alt="" />

      <h2>{_t("Протокол использования эластиков для экструзии.")}</h2>

      <p>
        {_t("В случаях, когда необходимо произвести экструзию зуба более чем на 3 мм, необходимо применить протокол:")}
      </p>
      <p>
        {_t(
          "- подготовка экструзируемых зубов к фиксации зацепов для эластиков, в соответствии со стандартным протоколом работы с композитным реставрационным материалом;",
        )}
        <br />
        {_t("- фиксация зацепов для эластиков на зубы в соответствии со стандартным протоколом;")} <br />
        {_t("- удаление излишков композитного материала;")}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/7.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "- фиксация элайнера с вырезами до режущего края в области экстру-зируемого зуба и надетыми эластиками в области вырезанных окон;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/8.jpg")} effect="blur" alt="" />

      <p>{_t("- фиксация краёв латексных эластиков на зацепы для эластиков;")}</p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/9.jpg")} effect="blur" alt="" />

      <p>
        {_t("- консультация пациента на предмет ношения элайнеров с системой эластиков;")} <br />
        {_t("- контроль процесса снятия и надевания пациентом элайнеров вместе с эластиками.")}
      </p>
      <h2>{_t("Рекомендации пациентам.")}</h2>
      <p>{_t("Чтобы использовать систему элайнеров и эластиков для экструзии, пациент должен:")}</p>
      <p>
        {_t(
          "- свернуть латексный эластик, рекомендованный врачом, в две петли и продеть через края элайнера до уровня вырезов под экструзируемый зуб;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/10.jpg")} effect="blur" alt="" />

      <p>
        {_t("- надеть элайнеры на зубы;")} <br />
        {_t(
          "- зацепить деревянной или пластиковой палочкой два края петли эластика в вырезанном окне и натянуть его на зафиксированный на зубе зацеп;",
        )}
        <br />
        {_t("- использовать рекомендации врача по срокам ношения эластиков и по их замене.")}
      </p>

      <CustomReactPlayer
        src={require("../../assets/1_using-elastics/video_3.mp4")}
        poster={require("../../assets/1_using-elastics/video_3.jpg")}
      />

      <h2>{_t("Эластики CL II")}</h2>
      <LazyLoadImage src={require("../../assets/1_using-elastics/11.jpg")} effect="blur" alt="" />

      <h2>{_t("Описание методики:")}</h2>
      <p>
        {_t(
          "Эластики II класса – межчелюстные тяги, которые применяются в случаях прикуса по прогнатическому (дистальному) типу. Данный тип эластиков используется для создания мезиальной тяги нижней челюсти. Назначаются при соотношении зубов по II классу по Энглю. Предназначены для нормализации соотношения жевательных зубов до соответствия с I классом по Энглю. Эластики II класса применятся в комплексе с элайнерами и усиливают их воздействие на зубы. Могут использоваться с одной стороны при одностороннем положении клыков и моляров, в соответствии со II классом по Энглю. Данная методика подразумевает использование латексных эластиков, зафиксированных на ортодонтических кнопках между первыми молярами нижней челюсти и клыками верхней челюсти. Жёсткость и сроки ношения определяются врачом-ортодонтом. Фиксацию эластиков нужно производить при надетых элайнерах. Систему эластиков II класса можно использовать как в начале лечения элайнерами, так и в процессе лечения.",
        )}
      </p>
      <h2>{_t("Протокол использования эластиков cl ii.")}</h2>
      <p>
        {_t(
          "В случаях, когда фиксация эластиков производится на элайнерах с уже выполненными вырезами под ортодонтические кнопки, необходимо применить протокол:",
        )}
      </p>
      <p>{_t("- фиксация элайнера с вырезами под ортодонтические кнопки в области нижних моляров на зубы;")}</p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/12.jpg")} effect="blur" alt="" />

      <LazyLoadImage src={require("../../assets/1_using-elastics/13.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "- подготовка зубов в области фиксации кнопки в соответствии со стандартным протоколом работы с композитным реставрационным материалом;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/14.jpg")} effect="blur" alt="" />

      <p>{_t("- фиксация ортодонтических кнопок на клыках верхней челюсти в вы-шеупомянутом порядке;")}</p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/15.jpg")} effect="blur" alt="" />

      <p>
        {_t("- удаление излишков композитного материала;")} <br />
        {_t("- контроль беспрепятственной посадки элайнеров на зубы;")} <br />
        {_t(
          "- фиксация латексных эластиков необходимой жёсткости между кнопками с каждой стороны при надетых элайнерах;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/16.jpg")} effect="blur" alt="" />

      <p>
        {_t("- консультация пациента на предмет ношения элайнеров с системой эластиков;")} <br />
        {_t("- контроль процесса снятия и надевания пациентом элайнеров вместе с эластиками.")}
      </p>
      <p>{_t("В случаях, когда система эластиков применяется в середине лечения, необходимо:")}</p>
      <p>
        {_t(
          "- изготовление вырезов каплевидной формы на верхнем элайнере в области клыков (диаметр самой широкой части выреза должен соответствовать диаметру основания ортодонтической кнопки);",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/17.jpg")} effect="blur" alt="" />

      <p>{_t("- создание вырезов на нижнем элайнере в области первых моляров.")}</p>
      <p>
        {_t(
          "В остальных действиях используется основной протокол. После фиксации кнопок все остальные элайнеры подгоняются при припасовке, если вырезы не были предусмотрены в начале лечения.",
        )}
      </p>
      <h2>{_t("Рекомендации пациентам.")}</h2>
      <p>{_t("Для использования системы элайнеров и эластиков для II класса пациенту необходимо:")}</p>
      <p>
        {_t("- надеть элайнеры на верхнюю и нижнюю челюсть;")} <br />
        {_t("- убедиться в том, что ортодонтические аппараты сели плотно на зубы;")} <br />
        {_t("- взять эластики, рекомендованные врачом – стоматологом ортодонтом;")} <br />
        {_t(
          "- надеть один край эластика на фиксатор, находящийся на дальнем жевательном зубе нижней челюсти, и другой край эластика на фиксатор на передней части верхней челюсти;",
        )}
        <br />
        {_t("- использовать рекомендации врача по срокам ношения эластиков и по их замене.")}
      </p>

      <CustomReactPlayer
        src={require("../../assets/1_using-elastics/video_1.mp4")}
        poster={require("../../assets/1_using-elastics/video_1.jpg")}
      />

      <h2>{_t("Эластики CL III")}</h2>
      <LazyLoadImage src={require("../../assets/1_using-elastics/18.jpg")} effect="blur" alt="" />
      <h2>{_t("Описание методики:")}</h2>

      <p>
        {_t(
          "Эластики III класса – межчелюстные тяги, которые применяются в случаях прикуса по прогеническому (мезиальному) типу. По системе TranspaDent используются для создания дистальной тяги, необходимой для дистализации группы жевательных зубов на нижней челюсти при соотношении по III классу по Энглю. Предназначены для нормализации соотношения жевательных зубов до соответствия с I классом по Энглю. Эластики III класса применятся в комплексе с элайнерами и усиливают их воздействие на зубы. Могут использоваться с одной стороны при одностороннем положении клыков и моляров в соответствии с III классом по Энглю. Данная методика подразумевает использование латексных эластиков, зафиксированных на ортодонтических кнопках между первыми молярами верхней челюсти и клыками или первыми премолярами нижней челюсти. Жёсткость и сроки ношения определяются врачом-ортодонтом. Фиксацию эластиков нужно производить при надетых элайнерах. Систему эластиков III класса можно использовать как в начале лечения элайнерами, так и в процессе лечения.",
        )}
      </p>
      <h2>{_t("Протокол использования эластиков CL III.")}</h2>
      <p>
        {_t(
          "В случаях, когда фиксация эластиков производится на элайнерах с уже выполненными вырезами под ортодонтические кнопки, необходимо применить протокол:",
        )}
      </p>
      <p>
        {_t(
          "- фиксация элайнера с вырезом под ортодонтическую кнопку в области верхних моляров на зубы верхней челюсти;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/19.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "- подготовка зуба в области фиксации кнопки в соответствии со стан-дартным протоколом работы с композитным реставрационным ма-териалом;",
        )}
        <br />
        {_t(
          "- фиксация ортодонтических кнопок в соответствии со стандартным протоколом, используя как ориентир специальные вырезы;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/20.jpg")} effect="blur" alt="" />

      <p>{_t("- фиксация ортодонтических кнопок на нижней челюсти в области клыков или первых премоляров;")}</p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/21.jpg")} effect="blur" alt="" />

      <p>
        {_t("- удаление излишков композитного материала;")} <br />
        {_t("- контроль беспрепятственного снятия и надевания элайнеров;")} <br />
        {_t(
          "- фиксация латексных эластиков необходимой жёсткости между кнопками с каждой стороны при надетых элайнерах;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/22.jpg")} effect="blur" alt="" />

      <p>
        {_t("- консультация пациента на предмет ношения элайнеров с системой эластиков;")} <br />
        {_t("- контроль процесса снятия и установки элайнеров вместе с эластиками пациентом.")}
      </p>
      <p>{_t("Если система эластиков применяется в середине лечения, необходимо:")}</p>
      <p>
        {_t(
          "- изготовление выреза каплевидной формы на верхнем элайнере в области первого моляра (диаметр самой широкой части выреза должен соответствовать диаметру основания ортодонтической кнопки);",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/23.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "- создание выреза на нижнем элайнере в области клыков или первых премоляров (в зависимости от клинического случая).",
        )}
      </p>
      <p>
        {_t(
          "В остальных действиях используется основной протокол. После фиксации кнопок все остальные элайнеры подгоняются при припасовке, если вырезы не были предусмотрены в начале лечения.",
        )}
      </p>
      <h2>{_t("Рекомендации пациентам.")}</h2>
      <p>{_t("Для использования системы элайнеров и эластиков для III класса пациенту необходимо:")}</p>
      <p>
        {_t("- надеть элайнеры на верхнюю и нижнюю челюсть;")} <br />
        {_t("- убедиться в том, что ортодонтические аппараты сели плотно на зубы;")} <br />
        {_t("- взять эластики, рекомендованные врачом – стоматологом ортодонтом;")} <br />
        {_t(
          "- надеть один край эластика на фиксатор, находящийся на дальнем жевательном зубе нижней челюсти, и другой край эластика на фиксатор на передней части верхней челюсти;",
        )}
        <br />
        {_t("- использовать рекомендации врача по срокам ношения эластиков и по их замене.")}
      </p>
      <CustomReactPlayer
        src={require("../../assets/1_using-elastics/video_2.mp4")}
        poster={require("../../assets/1_using-elastics/video_2.jpg")}
      />

      <h2>{_t("Эластики для перекрёстного прикуса")}</h2>
      <LazyLoadImage src={require("../../assets/1_using-elastics/24.jpg")} effect="blur" alt="" />
      <h2>{_t("Описание методики:")}</h2>

      <p>
        {_t(
          "Эластики для перекрёстного прикуса – это межчелюстные тяги, которые используются в случаях с перекрёстным прикусом. Применяются вместе с элайнерами для усиления их воздействия на зубы. Данная методика подразумевает использование латексных эластиков, зафиксированных на внутренней поверхности орально наклонённого зуба с одной стороны, и на вестибулярной поверхности зуба-антагониста, на противоположной челюсти с другой стороны. Для фиксации эластиков рекомендовано использовать ортодонтические кнопки или другие фиксаторы на зуба х. Фиксацию эластиков нужно производить при надетых элайнерах. Жёсткость и сроки ношения определяются врачом-ортодонтом. Систему эластиков для перекрёстного прикуса можно использовать как в начале лечения элайнерами, так и в процессе.",
        )}
      </p>
      <h2>{_t("Протокол использования эластиков для перекрёстного прикуса.")}</h2>
      <p>
        {_t(
          "В случаях, когда фиксация эластиков производится на элайнерах с уже выполненными вырезами под ортодонтические кнопки, необходимо применить протокол:",
        )}
      </p>
      <p>
        {_t("- фиксация элайнера с вырезами под ортодонтические кнопки на зубы;")} <br />
        {_t(
          "- подготовка зубов в области фиксации кнопки в соответствии со стандартным протоколом работы с композитным реставрационным материалом;",
        )}
        <br />
        {_t(
          "- фиксация ортодонтических кнопок на внутренней поверхности орально наклонённого зуба в соответствии со стандартным протоколом, используя как ориентир специальные вырезы на элайнерах;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/25.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "- фиксация ортодонтических кнопок на вестибулярной поверхности зуба-антагониста, используя как ориентир специальные вырезы;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/26.jpg")} effect="blur" alt="" />

      <p>
        {_t("- удаление излишков композитного материала;")} <br />
        {_t("- контроль беспрепятственной посадки элайнеров на зубы;")} <br />
        {_t(
          "- фиксация латексных эластиков необходимой жёсткости между кнопками с каждой стороны при надетых элайнерах;",
        )}
      </p>

      <LazyLoadImage src={require("../../assets/1_using-elastics/27.jpg")} effect="blur" alt="" />

      <p>
        {_t("- консультация пациента на предмет ношения элайнеров с системой эластиков;")} <br />
        {_t("- контроль процесса снятия и надевания пациентом элайнеров вместе с эластиками.")}
      </p>
      <p>{_t("Если система эластиков применяется в середине лечения, необходимо:")}</p>
      <p>
        {_t(
          "- изготовление вырезов каплевидной формы на внутренней поверхности орально наклонённого зуба (диаметр самой широкой части выреза должен соответствовать диаметру основания ортодонтической кнопки);",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/28.jpg")} effect="blur" alt="" />

      <p>{_t("- создание вырезов на вестибулярной поверхности зуба-антагониста.")}</p>
      <p>
        {_t(
          "В остальных действиях используется основной протокол. После фиксации кнопок все остальные элайнеры подгоняются при припасовке, если вырезы не были предусмотрены в начале лечения.",
        )}
      </p>
      <h2>{_t("Рекомендации пациентам.")}</h2>
      <p>{_t("Для использования системы элайнеров и эластиков для перекрёстного прикуса пациенту необходимо:")}</p>
      <p>
        {_t("- надеть элайнеры на верхнюю и нижнюю челюсть;")} <br />
        {_t("- убедиться в том, что ортодонтические аппараты сели плотно на зубы;")} <br />
        {_t("- взять эластики, рекомендованные врачом – стоматологом ортодонтом;")} <br />
        {_t(
          "- надеть один край эластика на фиксатор, находящийся на дальнем жевательном зубе нижней челюсти, и другой край эластика на фиксатор на передней части верхней челюсти;",
        )}
        <br />
        {_t("- использовать рекомендации врача по срокам ношения эластиков и по их замене.")}
      </p>
      <h2>{_t("Эластики для осуществления ротации")}</h2>
      <LazyLoadImage src={require("../../assets/1_using-elastics/29.jpg")} effect="blur" alt="" />
      <h2>{_t("Описание методики:")}</h2>

      <p>
        {_t(
          "Элайнер для ротации с использованием системы эластических тяг применяется для ускорения процесса ротации зуба в случаях, когда необходима ротация зуба более 30 градусов вокруг своей оси, низкая коронковая часть зуба, слабо выраженный экватор или зуб не поддаётся ротации при использовании классической системы элайнеров. Данная система эластиков является одночелюстной. Подобный элайнер изготавливается только в зуботехнической лаборатории, потому что он требует специальной подготовки модели для его изготовления. При проектировании модели для изготовления данного типа элайнера необходимо разделить цифровую постановку на несколько моделей, а затем объединить их в одну. Это необходимо сделать для того, чтобы создать пространство для движения зуба в капе.",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/30.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "В области ротируемого зуба на элайнере должен быть сделан каплевидный вырез под ортодонтическую кнопку, которая будет фиксироваться на зубе.",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/31.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "На расстоянии 10-15 мм от выреза в направлении ротации зуба должна быть зафиксирована ортодонтическая кнопка на элайнере. Фиксацию можно осуществить при помощи фотополимерного клея. ",
        )}
      </p>
      <h2>{_t("Протокол использования эластиков для ротации.")}</h2>
      <p>
        {_t(
          "В случаях, когда фиксация эластиков производится на элайнерах с уже выполненными вырезами под ортодонтические кнопки, необходимо применить протокол:",
        )}
      </p>
      <p>
        {_t("- фиксация элайнера с вырезом под ортодонтическую кнопку на ротируемом зубе;")} <br />
        {_t(
          "- подготовка зуба в области фиксации кнопки в соответствии со стандартным протоколом работы с композитным реставрационным материалом;",
        )}
        <br />
        {_t(
          "- фиксация ортодонтических кнопок в соответствии со стандартным протоколом, используя как ориентир специальные вырезы;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/32.jpg")} effect="blur" alt="" />

      <p>
        {_t("- удаление излишков композитного материала;")} <br />
        {_t("- контроль беспрепятственного снятия и надевания элайнеров;")}
      </p>

      <LazyLoadImage src={require("../../assets/1_using-elastics/33.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "- фиксация эластика на кнопку на зубе с одной стороны и кнопку на элайнере с другой (натяжение эластической тяги должно контролироваться врачом ортодонтом);",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/34.jpg")} effect="blur" alt="" />

      <p>
        {_t("- консультация пациента на предмет ношения элайнеров с системой эластиков;")} <br />
        {_t("- контроль процесса снятия и надевания пациентом элайнеров вместе с эластиками.")}
      </p>
      <p>{_t("Если система эластиков применяется в середине лечения, необходимо:")}</p>
      <p>
        {_t(
          "- изготовление выреза каплевидной формы на элайнере в области ротируемого зуба (диаметр самой широкой части выреза должен соответствовать диаметру основания ортодонтической кнопки);",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/1_using-elastics/35.jpg")} effect="blur" alt="" />

      <p>{_t("- фиксация кнопки на элайнере на расстоянии 10-15 мм от выреза в направлении ротации зуба.")}</p>
      <p>
        {_t(
          "В остальных действиях используется основной протокол. После фиксации кнопок все остальные элайнеры подгоняются при припасовке, если вырезы не были предусмотрены в начале лечения.",
        )}
      </p>
      <h2>{_t("Рекомендации пациентам.")}</h2>
      <p>{_t("Для использования системы элайнеров и эластиков для ротации пациенту необходимо:")}</p>
      <p>
        {_t("- наденьте выравниватели на челюсти;")} <br />
        {_t("- убедиться в том, что ортодонтический аппарат сел плотно на зубы;")} <br />
        {_t("- взять эластики, рекомендованные врачом – стоматологом-ортодонтом;")} <br />
        {_t(
          "- надеть один край эластика на фиксатор, находящийся элайнере и другой край эластика на фиксатор на зубе;",
        )}
        <br />
        {_t("- использовать рекомендации врача по срокам ношения эластиков и по их замене.")}
      </p>
    </>
  );
};
