const scrollToTop = (scrollDuration = 1000) => {
  // if (typeof window !== "undefined") return false;

  const scrollHeight = window.scrollY;
  const scrollStep = Math.PI / (scrollDuration / 15);
  const cosParameter = scrollHeight / 2;

  let scrollCount = 0;
  let scrollMargin = 0;

  let scrollInterval = setInterval(() => {
    if (window.scrollY !== 0) {
      scrollCount = scrollCount + 1;
      scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
      window.scrollTo(0, scrollHeight - scrollMargin);
    } else {
      clearInterval(scrollInterval);
    }
  }, 15);
};

export default scrollToTop;
