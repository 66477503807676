import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const PhotoProtocol = () => {
  const { t: _t } = useTranslation();
  return (
    <>
      <p>
        {_t(
          "Данный протокол имеет рекомендации по созданию изображений, необходимых для реализации планирования ортодонтического лечения элайнерами.",
        )}
      </p>
      <h2>{_t("Протокол действий:")}</h2>
      <p>{_t("– расположить пациента сидя на стуле или стоя на однотонном фоне;")}</p>
      <p>
        {_t(
          "– разместить фотокамеру таким образом, чтобы ее объектив находился на уровне Франкфуртской плоскости, а плоскость объектива параллельно лицевой плоскости;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/1.jpg")} effect="blur" alt="" />

      <p>{_t("– выставить фокусное расстояние в соответствии с параметрами объектива;")}</p>
      <p>{_t("– настроить свет и вспышку;")}</p>
      <p>
        {_t(
          "– выполнить съёмку и получить портретное фото пациента во фронтальной проекции с захватом всего лица в состоянии покоя нижней челюсти и расслабленных лицевых мышц;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/2.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "– выполнить съёмку и получить портретное фото пациента во фронтальной проекции с захватом всего лица в состоянии улыбки;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/3.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "– выполнить съёмку и получить фото пациента в сагиттальной (боковой) проекции с захватом всей головы в состоянии покоя нижней челюсти и расслабленных лицевых мышц;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/4.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "– выполнить съёмку и получить фото пациента в сагиттальной (боковой) проекции с захватом всей головы в состоянии улыбки;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/5.jpg")} effect="blur" alt="" />

      <p>{_t("– установить ретрактор в полость рта пациента;")}</p>
      <p>{_t("– убедиться в том, что ретракторы позволяют визуализировать зениты всех зубов;")}</p>
      <p>{_t("– выполнить съёмку и получить портретное фото пациента с ретракторами;")}</p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/6.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "– выполнить съёмку и получить фото зубов пациента с ретракторами во фронтальной проекции с захватом всех зубов;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/7.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "– выполнить съёмку и получить фото зубов пациента с ретракторами в боковой проекции справа с захватом всех видимых зубов;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/8.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "– выполнить съёмку и получить фото зубов пациента с ретракторами в боковой проекции слева с захватом всех видимых зубов;",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/9.jpg")} effect="blur" alt="" />

      <p>{_t("– разместить пациента в стоматологическом кресле;")}</p>
      <p>
        {_t("– установить в полость рта пациента интраоральное зеркало для визуализации верхней зубной дуги пациента;")}
      </p>
      <p>{_t("– выполнить съёмку и получить фото верхней зубной дуги пациента;")}</p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/10.jpg")} effect="blur" alt="" />

      <p>
        {_t("– установить в полость рта пациента интраоральное зеркало для визуализации нижней зубной дуги пациента;")}
      </p>
      <p>{_t("– выполнить съёмку и получить фото нижней зубной дуги пациента.")}</p>
      <LazyLoadImage src={require("../../assets/4_photo-protocol/11.jpg")} effect="blur" alt="" />
    </>
  );
};
