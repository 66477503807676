import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { Close } from "@material-ui/icons";
import React from "react";
import "./ClinicalProtocolsDialog.scss";
import { useTranslation } from "react-i18next";

export const ClinicalProtocolsDialog = ({ opened, toggle, title, content }) => {
  const { t: _t } = useTranslation();

  return (
    <Dialog className="clinical-protocols-dialog" onClose={toggle} open={opened} fullWidth maxWidth="md">
      <div className="clinical-protocols-dialog__contentBox">
        <Button className="clinical-protocols-dialog__close" onClick={() => toggle("")} variant="outlined">
          <span className="clinical-protocols-dialog__close-text">{_t("Закрыть")}</span>
          <Close />
        </Button>
        <div className="clinical-protocols-dialog__title">{title}</div>
        <div className="clinical-protocols-dialog__content">{content}</div>
      </div>
    </Dialog>
  );
};
