import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { Close } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import "./SourceRequirementsDialog.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { toggleDialog } from "../../actions/dialogsAction";

export const SourceRequirementsDialog = () => {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();

  const sourceRequirements = useSelector(({ sourceRequirements }) => sourceRequirements);

  const close = () => dispatch(toggleDialog("sourceRequirements", false));

  return (
    <Dialog className="source-requirements-dialog" open={sourceRequirements.isShow} onClose={close} maxWidth={"md"}>
      <div className="source-requirements-dialog__box">
        <Button className="clinical-protocols-dialog__close" onClick={close} variant={"outlined"}>
          {_t("Закрыть")}
          <Close />
        </Button>
        <h2>{_t("Компьютерная томография (КТ).")}</h2>
        <LazyLoadImage src={require("./assets/1.jpg")} effect="blur" alt="" />
        <p>
          {_t(
            "3D снимок зубов должен быть сделан обязательно в окклюзии или в зафиксированном центральном соотношении. В зоне захвата КТ снимка должны быть верхняя и нижняя челюсть полностью. Так же зона КТ должна захватывать ВНЧС с обеих сторон. Должны полностью просматриваться суставные головки нижней челюсти и суставные ямки. Документ должен быть предоставлен одним файлом, либо массивом изображений в открытом DCM формате.",
          )}
        </p>
        <h2>{_t("Цифровой скан челюстей.")}</h2>
        <LazyLoadImage src={require("./assets/2.jpg")} effect="blur" alt="" />

        <p>
          {_t(
            "Во всех случаях необходимо добавлять сканы верхней и нижней челюсти, даже если лечение необходимо на одной челюсти. Должно быть произведено цифровое сканирование всех зубов на челюсти. Необходимо обратить внимание на дистальные поверхности последних зубов. Они должны быть просканированы полностью. Мягкие ткани должны быть просканированы минимум 10 мм от шейки зуба. Ткани неба так же должны быть просканированы, а также желательно захватывать зоны с тяжами и уздечками с щечной стороны. Сканы челюстей должны быть сохранены в окклюзии. Если по какой-то причине цифровые модели не были сохранены в окклюзии, необходимо добавить цифровой скан прикуса. Сканы должны быть предоставлены в открытом файле STL формата. После сохранения все сканы и модели формата STL доступны для просмотра.",
          )}
        </p>
        <h2>{_t("Панорамный снимок челюстей (ОПТГ, ортопантомограмма).")}</h2>
        <LazyLoadImage src={require("./assets/3.jpg")} effect="blur" alt="" />

        <p>
          {_t(
            "Панорамный снимок должен быть сделан в соответствии со всеми рентгенографическими правилами. Должны быть видны все зубы на снимке, а контуры зубов и кости должны быть четкими. При создании заказа должны быть добавлены оригинальные цифровые снимки или сканы аналоговых снимков. Фотографии, сделанные с распечатанного снимка, не допускаются. Панорамный снимок должен быть добавлен в любом из открытых форматов файла.",
          )}
        </p>

        <h2>{_t("Цифалометрический снимок (ТРГ, телерентгенограмма)")}</h2>
        <LazyLoadImage src={require("./assets/4.jpg")} effect="blur" alt="" />
        <p>
          {_t(
            "ТРГ должен быть сделан в боковой проекции, в соответствии со всеми рентгенографическими правилами. Контуры зубов и кости должны четко просматриваться на снимке. При создании заказа должны быть добавлены оригинальные цифровые снимки или сканы аналоговых снимков. Фотографии, сделанные с распечатанного снимка, не допускаются. Панорамный снимок должен быть добавлен в любом из открытых форматов файла.",
          )}
        </p>

        <h2>{_t("Фото пациента")}</h2>
        <LazyLoadImage src={require("./assets/16.png")} effect="blur" alt="" />
        <p>
          {_t(
            "Фотографии пациента, необходимые для составления ортодонтического плана лечения элайнерами, должны быть сделаны в соответствии с Фото протоколом.",
          )}
        </p>

        <h2>{_t("Фото Протокол")}</h2>
        <p>
          {_t(
            "Данный протокол имеет рекомендации по созданию изображений, необходимых для реализации планирования ортодонтического лечения элайнерами.",
          )}
        </p>

        <h2>{_t("Протокол действий:")}</h2>
        <p>
          {_t("– расположить пациента сидя на стуле или стоя на однотонном фоне;")} <br />
          {_t(
            "– разместить фотокамеру таким образом, чтобы ее объектив находился на уровне Франкфуртской плоскости, а плоскость объектива параллельно лицевой плоскости;",
          )}
        </p>
        <LazyLoadImage src={require("./assets/5.jpg")} effect="blur" alt="" />

        <p>
          {_t("– выставить фокусное расстояние в соответствии с параметрами объектива;")} <br />
          {_t("– настроить свет и вспышку;")} <br />
          {_t(
            "– выполнить съёмку и получить портретное фото пациента во фронтальной проекции с захватом всего лица в состоянии покоя нижней челюсти и расслабленных лицевых мышц;",
          )}
        </p>
        <LazyLoadImage src={require("./assets/6.png")} effect="blur" alt="" />

        <p>
          {_t(
            "– выполнить съёмку и получить портретное фото пациента во фронтальной проекции с захватом всего лица в состоянии улыбки;",
          )}
        </p>
        <LazyLoadImage src={require("./assets/7.jpg")} effect="blur" alt="" />

        <p>
          {_t(
            "– выполнить съёмку и получить фото пациента в сагиттальной (боковой) проекции с захватом всей головы в состоянии покоя нижней челюсти и расслабленных лицевых мышц;",
          )}
        </p>
        <LazyLoadImage src={require("./assets/8.jpg")} effect="blur" alt="" />

        <p>
          {_t(
            "– выполнить съёмку и получить фото пациента в сагиттальной (боковой) проекции с захватом всей головы в состоянии улыбки;",
          )}
        </p>
        <LazyLoadImage src={require("./assets/9.jpg")} effect="blur" alt="" />

        <p>
          {_t("– установить ретрактор в полость рта пациента;")} <br />
          {_t("– убедиться в том, что ретракторы позволяют визуализировать зениты всех зубов;")} <br />
          {_t("– выполнить съёмку и получить портретное фото пациента с ретракторами;")}
        </p>
        <LazyLoadImage src={require("./assets/10.jpg")} effect="blur" alt="" />

        <p>
          {_t(
            "– выполнить съёмку и получить фото зубов пациента с ретракторами во фронтальной проекции с захватом всех зубов;",
          )}
        </p>
        <LazyLoadImage src={require("./assets/11.jpg")} effect="blur" alt="" />

        <p>
          {_t(
            "– выполнить съёмку и получить фото зубов пациента с ретракторами в боковой проекции справа с захватом всех видимых зубов;",
          )}
        </p>
        <LazyLoadImage src={require("./assets/12.jpg")} effect="blur" alt="" />

        <p>
          {_t(
            "– выполнить съёмку и получить фото зубов пациента с ретракторами в боковой проекции слева с захватом всех видимых зубов;",
          )}
        </p>
        <LazyLoadImage src={require("./assets/13.jpg")} effect="blur" alt="" />

        <p>
          {_t("– разместить пациента в стоматологическом кресле;")} <br />
          {_t(
            "– установить в полость рта пациента интраоральное зеркало для визуализации верхней зубной дуги пациента;",
          )}
          <br />
          {_t("– выполнить съёмку и получить фото верхней зубной дуги пациента;")}
        </p>
        <LazyLoadImage src={require("./assets/14.jpg")} effect="blur" alt="" />

        <p>
          {_t(
            "– установить в полость рта пациента интраоральное зеркало для визуализации нижней зубной дуги пациента;",
          )}
          <br />
          {_t(
            "– установить в полость рта пациента интраоральное зеркало для визуализации нижней зубной дуги пациента;",
          )}
        </p>
        <LazyLoadImage src={require("./assets/15.jpg")} effect="blur" alt="" />

        <h2>{_t("Оттиски челюстей:")}</h2>
        <p>
          {_t(
            "Для планирования ортодонтического лечения элайнерами необходимо изготовление качественных силиконовых оттисков, полученных одноэтапным или двухэтапным способом, но обязательно с корригирующим слоем. Необходимо соблюдать рекомендации по области снятия слепка.",
          )}
        </p>
        <p>
          {_t(
            "Все шейки зубов должны быть хорошо видны, включая дистальные поверхности 7-ых зубов. Для данного вида работ подходят слепочные массы, как из С-силикона, так и из А-силикона. Предпочтительно использовать А-силикон по той причине, что данный материал позволяет более быстро нанести корригирующий слой до начала застывания базового слоя. Во всех случаях планирования ортодонтического лечения необходимы оттиски на обе челюсти.",
          )}
        </p>
        <LazyLoadImage src={require("./assets/17.jpg")} effect="blur" alt="" />
      </div>
    </Dialog>
  );
};
