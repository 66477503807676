import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Assignment, Call, CardTravel, InsertDriveFile, ThreeDRotation } from "@material-ui/icons";
import "./ForDoctorsPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ClinicalProtocols from "../#ClinicalProtocols";
import SourceRequirementsDialog from "../#SourceRequirementsDialog";
import { toggleDialog } from "../../actions/dialogsAction";
import { ROUTES } from "../../constants/routes";
import { loadLink } from "../../modules/loadLink";
import scrollToTop from "../../modules/scrollToTop";
import screens from "../../assets/img/forDoctors/presentation-screens.png";

export const ForDoctorsPage = () => {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();

  const sourceRequirements = useSelector(({ sourceRequirements }) => sourceRequirements);

  useEffect(() => {
    scrollToTop(600);
  }, []);

  return (
    <>
      {sourceRequirements.isShow && <SourceRequirementsDialog />}
      <header className="header-fd">
        <div className="header-fd__head">
          <div className="container">
            <Link to={ROUTES.home} className="header-fd__small-logo">
              <img src={require("../../assets/img/logo_dark.png")} alt="" />
            </Link>
          </div>
        </div>
        <div className="container">
          <div className="header-fd__logo">
            <img src={require("../../assets/img/logo_light.png")} alt="" />
          </div>
          <h1>{_t("Для врачей")}</h1>
          <div className="header-fd__btns">
            <Button variant={"contained"} color={"secondary"} onClick={() => dispatch(toggleDialog("contacts", true))}>
              {_t("Контакты представителей")}
            </Button>
            <Button
              component={"a"}
              href={"https://colleagial.com"}
              target="_blank"
              rel="noopener noreferrer"
              variant={"contained"}
              color={"primary"}
            >
              {_t("Вход и регистрация на colleagial")}
            </Button>
          </div>
        </div>
      </header>
      <section className="how-to-order with-title">
        <div className="container">
          <h2 className="section-title">{_t("Как заказать у нас элайнеры?")}</h2>
          <p className="text-center">{_t("Для того, чтобы заказать элайнеры TranspaDent необходимо:")}</p>
          <div className="how-to-order__items">
            <div className="how-to-order__item">
              <Call />
              <p>{_t("связаться с представителем;")}</p>
            </div>
            <div className="how-to-order__item">
              <Assignment />
              <p>{_t("заполнить электронный сопроводительный лист;")}</p>
            </div>
            <div className="how-to-order__item">
              <InsertDriveFile />
              <p>
                {_t(
                  "передать представителям компании исходные данные, необходимые для планирования ортодонтического лечения элайнерами;",
                )}
              </p>
            </div>
            <div className="how-to-order__item">
              <ThreeDRotation />
              <p>
                {_t("изучить и подтвердить план ортодонтического лечения, предоставленный специалистами компании;")}
              </p>
            </div>
            <div className="how-to-order__item">
              <CardTravel />
              <p>{_t("через 7 дней после подтверждения плана лечения получить готовые элайнеры.")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="indications-for-use with-title">
        <div className="container">
          <div className="indications-for-use__texts">
            <h2 className="section-title_dark">{_t("Показания для использования элайнеров TranspaDent")}</h2>
            <p>
              {_t(
                "Ортодонтическое лечение элайнерами актуально для большинства клинических случаев. Список зубо - челюстных аномалий, которые можно исправить элайнерами TranspaDent представлен в списке патологий. В данном разделе так же представлены необходимые исходные данные  для планирования ортодонтического лечения элайнерами по каждому типу аномалий. Используйте список патологий для фиксации аномалии для исправления при создании заказа элайнеров. Изучите визуализации клинических случаев и используйте их для коммуникации с пациентами.",
              )}
            </p>
            <Button component={Link} to={ROUTES.classificationOfAnomalies} variant={"contained"} color={"primary"}>
              {_t("Просмотреть классификацию аномалий")}
            </Button>
          </div>

          <div className="indications-for-use__pic">
            <img src={require("../../assets/img/forDoctors/indications-for-use.png")} alt="" />
          </div>
        </div>
      </section>

      <section className="how-made with-title">
        <div className="container">
          <h2 className="section-title">{_t("Как изготавливается система элайнеров?")}</h2>
          <div className="how-made__items">
            <div className="how-made__item">
              <img src={require("../../assets/img/forDoctors/how1.png")} alt="" />
              <p>{_t("После получения слепков производится 3D сканирование и получение 3D моделей")}</p>
            </div>
            <div className="how-made__item">
              <img src={require("../../assets/img/forDoctors/how2.png")} alt="" />
              <p>{_t("Вся система разрабатывается в специальной 3D-программе")}</p>
            </div>
            <div className="how-made__item">
              <img src={require("../../assets/img/forDoctors/how3.png")} alt="" />
              <p>{_t("После проектирования системы выравнивания производится 3D печать рабочих моделей")}</p>
            </div>
            <div className="how-made__item">
              <img src={require("../../assets/img/forDoctors/how4.png")} alt="" />
              <p>{_t("По напечатанным моделям изготавливаются прозрачные каппы для выравнивания")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="threeD with-title">
        <div className="container">
          <h2 className="section-title">{_t("3D визуализации элайнеров TranspaDent")}</h2>
          <div className="threeD__pics">
            <a
              href="https://www.dentabula.com/viewer_vr/5ad7104e61662f3018ce7225"
              target="_blank"
              rel="noopener noreferrer"
              className="threeD__link"
            >
              <img className="threeD__pic" src={require("../../assets/img/forDoctors/ddd1.jpg")} alt="" />
              <span className="threeD__overlay">
                <img src={require("../../assets/img/forDoctors/ddd-icon.png")} alt="" />
              </span>
            </a>
            <a
              href="https://www.dentabula.com/viewer_vr/5b234a151a2ae56b3e487ec8"
              target="_blank"
              rel="noopener noreferrer"
              className="threeD__link"
            >
              <img className="threeD__pic" src={require("../../assets/img/forDoctors/ddd2.jpg")} alt="" />
              <span className="threeD__overlay">
                <img src={require("../../assets/img/forDoctors/ddd-icon.png")} alt="" />
              </span>
            </a>
          </div>
        </div>
      </section>

      <section className="clinical-protocols-section with-title">
        <div className="container">
          <h2 className="section-title">{_t("Клинические протоколы")}</h2>
          <p className="clinical-protocols-section__subtitle">
            {_t("Для выполнения всех методических рекомендаций необходимо использовать клинические протоколы.")}
          </p>
          <ClinicalProtocols />
        </div>
      </section>

      <section className="initial-data-requirements with-title">
        <div className="container">
          <div className="initial-data-requirements__texts">
            <h2 className="section-title_dark">{_t("Требования к исходным данным")}</h2>
            <p>
              {_t(
                "Для создания качественного плана ортодонтического лечения элайнерами, необходимы качественные исходные данные. Список исходных данных к каждой аномалии представлен в разделе классификации аномалий. Требования к исходным данным можно просмотреть кликнув по кнопке.",
              )}
            </p>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => dispatch(toggleDialog("sourceRequirements", true))}
            >
              {_t("Посмотреть требования к данным")}
            </Button>
          </div>
          <div className="initial-data-requirements__pic">
            <img src={require("../../assets/img/forDoctors/initial-data-requirements.png")} alt="" />
          </div>
        </div>
      </section>

      <section className="project with-title">
        <div className="container">
          <p>
            {_t(
              "Процесс проектирования элайнеров производится в соответствии с протоколами компании, которые включают в себя разнообразные ортодонтические аномалии. Все протоколы объединены в методическое пособие, которое издано вбумажном виде для комфортной работы с пациентами.",
            )}
          </p>
          <div className="project__block-images">
            <div>
              <img src={require("../../assets/img/forDoctors/1.png")} alt="" />
            </div>
            <div>
              <img src={require("../../assets/img/forDoctors/2.png")} alt="" />
            </div>
          </div>
          <p>{_t("Узнать больше о системе TranspaDent можно изучив презентацию")}</p>
          <div className="project__presentation">
            <div className="project__screens">
              <img src={screens} alt="" />
            </div>
            <Button
              variant="contained"
              color="primary"
              className="project__presentation-btn"
              onClick={() =>
                loadLink(
                  "https://files.transpadent.org/client_guide_transpadent/client_guide_transpadent.pdf",
                  "newTab",
                )
              }
            >
              {_t("Посмотреть презентацию")}
            </Button>
          </div>
          <p>
            {_t(
              "Для качественной коммуникации с врачами стоматологами и создания презентаций для пациентов, компания TranspaDent использует интернет-платформу",
            )}
            <a href="https://colleagial.com/" style={{ marginLeft: 5 }} target="_blank" rel="noopener noreferrer">
              colleagial.com
            </a>
          </p>
          <div className="project__block-images">
            <div className="project__register">
              <img src={require("../../assets/img/forDoctors/collegial.png")} alt="Colleagial" />
              <Button
                component={"a"}
                href={"https://colleagial.com/"}
                target="_blank"
                rel="noopener noreferrer"
                variant={"contained"}
                color={"primary"}
              >
                {_t("Зарегистрироваться")}
              </Button>
            </div>
            <div>
              <img src={require("../../assets/img/forDoctors/3.png")} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
