import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const RestorationProtocol = () => {
  const { t: _t } = useTranslation();
  return (
    <>
      <h2>{_t("Описание методики:")}</h2>
      <p>
        {_t(
          "Во многих случаях после ортодонтического лечения можно наблюдать нарушения окклюзионных контактов, вызванных повышенной неравномерной стираемостью зубной эмали вследствие неправильного положения зуба относительно антагониста. Данная патология требует восстановления формы зуба композитными материалами. В противном случае присутствует риск рецидивов, и нарушения функций пережёвывания пищи. Также в большинстве случаев данный результат приводит к на-рушениям эстетики.",
        )}
      </p>
      <p>
        {_t(
          "В явных проявлениях подобную патологию можно наблюдать при нарушениях ротации зуба и при сужении зубных дуг, за счёт нёбной или язычной инклинации зубов. В таких случаях реставрация вестибулярных бугров является частью процедуры фиксации результата и идеализации прикуса.",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/5_restoration-protocol/2.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "Данную реставрацию важно провести с учётом латеральных движений нижней челюсти с сохранением контактов на жевательных зубах. Сохранение контактов на буграх жевательных зубов при латеральном движении нижней челюсти – это важный фактор, необходимый для сохранения качественного результата ортодонтического лечения на долгий срок. При движении нижней челюсти влево должны сохраняться контакты на клыке, вестибулярных буграх первого и второго премоляра и на мезиальном щёчном бугре первого моляра. Размыкание бугров должно происходить последовательно, по принципу «ножниц». Первым должен выходить из контакта клык, затем первый премоляр и так далее.",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/5_restoration-protocol/3.jpg")} effect="blur" alt="" />

      <p>
        {_t(
          "Подобный принцип должен применяться с обеих сторон синхронно с сохранением контактов на нёбных буграх жевательных зубов верхней челюсти и язычных поверхностях вестибулярных бугров нижней челюсти, с обратной стороны направления латерального движения нижней челюсти.",
        )}
      </p>
      <h2>{_t("Протокол реставрации окклюзионных поверхностей:")}</h2>
      <p>{_t("Для проведения реставраций окклюзионных поверхностей необходимо выполнить следующие действия:")}</p>
      <p>
        {_t("- проанализировать степень стираемости зубов;")} <br />
        {_t("- проанализировать окклюзионные контакты в области клыков премоляров и моляров;")} <br />
        {_t("- проанализировать клыковые пути, образовывающиеся при латеральном движении нижней челюсти;")} <br />
        {_t("- подготовить зубы к реставрации композитными материалами;")} <br />
        {_t(
          "- при отсутствии корректного клыкового пути восстановить клыки на верхней и нижней челюсти до достижения корректного латерального движения;",
        )}
        <br />
        {_t(
          "- восстановить бугры первых премоляров таким образом, чтобы движение вестибулярной поверхности щёчного бугра нижнего премоляра сохраняла контакт с язычной поверхностью щёчного бугра верхнего премоляра на протяжении всего клыкового пути при сагиттальном движении нижней челюсти до потери контакта на клыках;",
        )}
        <br />
        {_t(
          "- проконтролировать контакт нёбного бугра первого премоляра с язычной поверхностью щёчного бугра нижнего первого премоляра и при необходимости восстановить;",
        )}
        <br />
        {_t("- провести подобную реставрацию на вторых премолярах;")} <br />
        {_t("- применить подобный подход на молярах;")} <br />
        {_t(
          "- проконтролировать синхронность скольжения вестибулярных бугров жевательных зубов, повторяющих клыковый путь при латеральном движении нижней челюсти;",
        )}
        <br />
        {_t(
          "- проконтролировать последовательное размыкание вестибулярных бугров жевательных зубов по принципу «ножниц»;",
        )}
        <br />
        {_t("- произвести полировку реставраций в соответствии со стандартным протоколом.")}
      </p>
    </>
  );
};
