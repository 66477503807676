import { Close } from "@material-ui/icons";
import React, { useState } from "react";
import "./ClassificationOfAnomaliesDialog.scss";

import { CircularProgress, Dialog, IconButton, Slider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

export const ClassificationOfAnomaliesDialog = ({ data, isShow, toggle }) => {
  const { t: _t } = useTranslation();

  const [currentPhoto, setCurrentPhoto] = useState(1);
  const [loading, setLoading] = useState([]);

  const changeSlide = (value) => setCurrentPhoto(value);

  const isLoading = loading.length !== data?.slides?.length;

  return (
    <Dialog fullScreen open={isShow} onClose={toggle} className="classificationOfAnomaliesDialog">
      <Link to={ROUTES.home} className="classificationOfAnomaliesDialog__logo">
        <img src={require("../../../assets/img/logo_dark.png")} alt="" />
      </Link>
      <IconButton onClick={toggle} className="classificationOfAnomaliesDialog__close">
        <Close />
      </IconButton>
      <section>
        <div className="item" key={data.id}>
          <h2>{data.title}</h2>
          <p>{_t("Визуализация лечения")}</p>
          {data.slides && (
            <div className="mySlider">
              <div className="pics">
                <>
                  {isLoading && <CircularProgress className="progress" />}

                  {data.slides.map((el) => (
                    <img
                      onLoad={() => setLoading((prevState) => [...prevState, el.id])}
                      src={el.pic}
                      alt=""
                      key={el.id}
                      className={`${currentPhoto === el.id && "active"} ${isLoading ? "loading" : ""}`}
                    />
                  ))}
                </>
              </div>
              <div className="action">
                <Slider
                  disabled={isLoading}
                  value={currentPhoto}
                  step={1}
                  marks
                  min={1}
                  max={data?.slides?.length}
                  onChange={(e, value) => changeSlide(value)}
                />
              </div>
            </div>
          )}
          <div className="dataWrap">
            <p>{_t("Необходимые исходные данные:")}</p>
            <div className="data">
              {data.necData.map((el) => (
                <div className="pic" title={el.title} key={el.title}>
                  <img src={el.pic} alt={el.title} />
                  <p>{el.title}</p>
                </div>
              ))}
            </div>
            {data.clarification && (
              <>
                <p>{_t("Классификация:")}</p>
                {data.clarification.map((el) => (
                  <p className="clarification" key={el}>
                    {el}
                  </p>
                ))}
              </>
            )}
          </div>
        </div>
      </section>
    </Dialog>
  );
};
