import { Button, Dialog } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./MainPage.scss";
import { BigPlayButton, Player } from "video-react";
import { SliderWithArrows } from "../#SliderWithArrows/SliderWithArrows";
import { agentsList } from "../../agentsList.js";
import { ROUTES } from "../../constants/routes";
import scrollToTop from "../../modules/scrollToTop";
import { slides } from "./slides";
import howToUseAligners from "../../assets/videos/how-to-use-aligners.mp4";
import howToUseAlignersPoster from "../../assets/videos/how-to-use-aligners-poster.jpg";

export const MainPage = () => {
  const { t: _t } = useTranslation();

  useEffect(() => {
    scrollToTop(600);
  }, []);

  const { push } = useHistory();
  const { hash } = useLocation();

  const toggleDialogWithVideo = (hash) => push({ hash });

  return (
    <>
      <header className="header">
        <div className="container">
          <div className={"header__logoBox"}>
            <div className={"header__logo"}>
              <img src={require("../../assets/img/logo_dark.png")} alt="TranspaDent" />
            </div>
            <div className={"header__btns"}>
              <Link to={ROUTES.forDoctors} className="header__doc">
                {_t("Я врач")}
              </Link>
              <a href={"https://colleagial.com/"} className={"header__enter"}>
                {_t("Вход в Colleagial")}
              </a>
            </div>
          </div>
          <h1>{_t("Элайнеры TranspaDent — эстетическое выравнивание зубов")}</h1>
          <div className="header__pics">
            <img src={require("../../assets/img/home/header1.png")} alt="" />
            <img src={require("../../assets/img/home/header2.png")} alt="" />
            <img src={require("../../assets/img/home/header3.png")} alt="" />
          </div>
        </div>
      </header>
      <section className="advs">
        <div className="container">
          <div className="advs__box">
            <div className="advs__item">
              <p className="advs__title advs__title_1">{_t("Эстетика")}</p>
              <img src={require("../../assets/img/home/advs1.png")} alt={_t("Эстетика")} />
              <p className={"advs__text"}>{_t("Каппы TranspaDent практически невидимы")}</p>
            </div>
            <div className="advs__item">
              <p className="advs__title advs__title_2">{_t("Безопасность")}</p>
              <img src={require("../../assets/img/home/advs2.png")} alt={_t("Безопасность")} />
              <p className={"advs__text"}>
                {_t(
                  "Методика не подразумевает использования металла Каппы не оказывают никакого негативного воздействия на зубы",
                )}
              </p>
            </div>
            <div className="advs__item">
              <p className="advs__title advs__title_3">{_t("Гигиена")}</p>
              <img src={require("../../assets/img/home/advs3.png")} alt={_t("Гигиена")} />
              <p className={"advs__text"}>
                {_t("Каппы позволяют безпрепятственно чистить зубы и употреблять в пищу любые продукты питания")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="o">
        <div className="o__pic img-box">
          <img src={require("../../assets/img/home/o.png")} alt="" />
        </div>
        <p className="o__text">
          <b>{_t("Элайнер")}</b>
          {_t(
            " — съемное ортодонтическое устройство (капа), в котором заложена нагрузка для дозированного передвижения зубов. Изготавливается из высокопрочного прозрачного ﻿полимера. Благодаря тонкой и прозрачной структуре элайнер прекрасно фиксируется на зубах, а его присутствие практически незаметно для окружающих, даже при близком контакте. Дискомфорт от использования элайнеров исчезает уже после первой недели ношения.",
          )}
        </p>
      </section>
      <section className="view with-title">
        <div className="container">
          <h2 className="section-title">{_t("Как выглядит элайнер?")}</h2>
          <SliderWithArrows slides={slides} firstSlide={1} maxWidth={700} />
        </div>
      </section>
      <section className="video">
        <video
          src={require("../../assets/videos/1.mp4")}
          preload={"true"}
          autoPlay
          playsInline
          muted
          loop
          poster={require("../../assets/videos/poster2.jpg")}
        />
      </section>
      <section className="vi">
        <p className="vi__text">
          <b>{_t("Система элайнеров TranspaDent")}</b>
          {_t(
            " - это капы для выравнивания зубов, в каждой из которых заложена дозированная нагрузка, рассчитанная на пошаговое передвижение зубов при условии своевременной смены кап. Элайнеры были созданы в качестве альтернативы брекет системам и сейчас они являются самой эстетичной, гигиеничной и безопасной методикой ортодонтического лечения. В отличие от брекетов, элайнеры TranspaDent не имеют металлических элементов, поэтому не травмируют дёсна и губы. За счёт съёмной конструкции, капы для выравнивания зубов не разрушают зубную эмаль и не способствуют появлению неприятного запаха изо рта. Системы элайнеров — самый высокотехнологичный способ выравнивания зубов.",
          )}
        </p>
        <div className="vi__pic img-box">
          <img src={require("../../assets/img/home/vi.png")} alt="" />
        </div>
      </section>
      <section className="align with-title">
        <div className="container">
          <h2 className="section-title">{_t("Как элайнеры выравнивают зубы?")}</h2>
          <p>
            {_t(
              "При поэтапной смене элайнеров каждые 2-3 недели происходит пошаговое дозированное перемещение зубов до достижения запланированного результата. Использовать элайнеры нужно 20 -22 часа в сутки.",
            )}
          </p>
          <div className="align__pics">
            <img src={require("../../assets/img/home/align1.png")} alt="" />
            <img src={require("../../assets/img/home/align2.png")} alt="" />
            <img src={require("../../assets/img/home/align3.png")} alt="" />
            <img src={require("../../assets/img/home/align4.png")} alt="" />
            <img src={require("../../assets/img/home/align5.png")} alt="" />
          </div>
          <p style={{ margin: "20px 0 10px" }}>
            {_t(
              "Между костью челюсти и зубами расположен слой соединительной ткани, который прочно фиксирует зубы. В то же время волокна соединительной ткани достаточно эластичные. Это позволяет зубам прорезываться и менять собственное положение при длительном механическом давлении. Элайнеры оказывая небольшое, но стабильное давление на зубы постепенно меняют их положение.",
            )}
          </p>
          <p>
            {_t(
              "Происходит это благодаря плавному рассасыванию соединительной ткани в зубных лунках. После того как зубы примут нужное положение и давление элайнеров на них прекратится, начнётся процесс заживления и укрепления зубных лунок, которые приобрели новую форму.",
            )}
          </p>
        </div>
      </section>

      <section className="video">
        <video
          src={require("../../assets/videos/2.mp4")}
          preload={"true"}
          autoPlay
          playsInline
          muted
          loop
          poster={require("../../assets/videos/poster2.jpg")}
        />
      </section>

      <section className="how-it-works with-title">
        <div className="container">
          <h2 className="section-title">{_t("Как выровнять зубы элайнерами?")}</h2>
          <div className="how-it-works__items">
            <div className="how-it-works__item">
              <img src={require("../../assets/img/home/how-it-works1.png")} alt="" />
              <p>{_t("Свяжитесь с нашим представителем")}</p>
            </div>
            <div className="how-it-works__item">
              <img src={require("../../assets/img/home/how-it-works2.png")} alt="" />
              <p>{_t("Обратитесь к рекомендованному представителем врачу")}</p>
            </div>
            <div className="how-it-works__item">
              <img src={require("../../assets/img/home/how-it-works3.png")} alt="" />
              <p>{_t("Доктор снимет оттиски ваших зубов и сделает необходимые снимки")}</p>
            </div>
            <div className="how-it-works__item">
              <img src={require("../../assets/img/home/how-it-works4.png")} alt="" />
              <p>{_t("Получите готовые элайнеры от вашего врача")}</p>
            </div>
          </div>
          <h3 style={{ margin: "30px 0 15px" }}>{_t("Лечение элайнерами. Подготовка к созданию кап.")}</h3>
          <p>
            {_t(
              "После того как пациент даёт врачу предварительное согласие на исправления прикуса элайнерами, доктор начинает сбор необходимых данных. Это фото лица, рентгеновские снимки, слепки зубов и фото пациента. Далее с помощью специализированного программного обеспечения, на основе полученных данных, создаётся 3D план ортодонтического лечения элайнерами. В процессе создания проекта, специалист компании TranspaDent сможет провести детальные расчёты необходимого количество элайнеров. После создания плана лечения доктор может озвучить пациенту сроки лечения элайнерами, назвать общую стоимость всех кап и самое главное, продемонстрировать пациенту готовый результат лечения, ещё до его начала.",
            )}
          </p>
        </div>
      </section>

      <section className="agents with-title">
        <div className="container">
          <h2 className="section-title">{_t("Контакты представителей:")}</h2>

          {agentsList(_t).map(({ id, location, tel }) => (
            <div className="agents__agent" key={id}>
              <h4>{location}</h4>
              <a href={`tel:${tel.replace(/ /g, "")}`}>{tel}</a>
            </div>
          ))}
        </div>
      </section>

      <h2 className="section-title">{_t("Как использовать и ухаживать за элайнерами?")}</h2>
      <section className="video-poster" onClick={() => toggleDialogWithVideo("#how-to-use")}>
        <img src={howToUseAlignersPoster} alt="" />
      </section>

      <section className="how-texts with-title">
        <div className="container">
          <p className="how-texts__text">
            {_t(
              "Срок ношения кап (элайнеров) варьируется в пределах от 3 до 18 месяцев, в зависимости от сложности клинического случая. Средняя продолжительность ношения капы для исправления прикуса составляет 8-10 месяцев. Согласно установленному плану лечения, смена элайнеров осуществляется пациентом самостоятельно 1 раз в2 недели. Посещать стоматолога, контролирующего процесс лечения необходимо 1 раз в 2 месяца.",
            )}
          </p>
          <p className="how-texts__text fw700 mt3">{_t("Как носить элайнеры")}</p>
          <p className="how-texts__text">
            {_t(
              "Для достижения прогнозируемого результата лечения в установленные сроки, пациент должен строго придерживаться предписаний своего ортодонта. Носить капы для выравнивания зубов необходимо не менее 20 часов в сутки. Снимать их рекомендуется только во время приёма пищи, и чистки зубов. Чтобы сохранить целостность и эстетичный внешний вид элайнеров прикасаться к ним следует предварительно вымыв руки. Надевать капы нужно после тщательного удаления остатков пищи, которые могли застрять между зубами. Наличие на зубах и между ними крупных остатков пищи может ослабить фиксацию и, как следствие, воздействие элайнеров.",
            )}
          </p>
          <p className="how-texts__text fw700 mt3">{_t("Уход за элайнерами")}</p>
          <p className="how-texts__text">
            {_t(
              "Несколько простых правил по уходу за капами (элайнерами) помогут сохранить их эстетический, прозрачный вид. Промывать изделия следует под прохладной водой. Мыть в горячей воде или замачивать в специальных растворах капы нельзя, поскольку это может негативно сказаться на их внешнем виде и повредить их структуру. Очищаются элайнеры очень просто, при помощи обычной мягкой зубной щётки и небольшого слоя зубной пасты. Хранить изделия следует в специальном футляре, который защищает капы от механических повреждений и загрязнения.",
            )}
          </p>
          <p className="how-texts__text fw700 mt3">{_t("Ограничения использования")}</p>
          <p className="how-texts__text">
            {_t(
              "Когда капы находятся на зубах запрещено потребление пищи и распитие горячих или содержащих красители напитков. Следует вовсе отказаться или хотя бы ограничить курение, поскольку табачный дым негативно влияет на степень прозрачности элайнеров. Благодаря съёмной конструкции капы для выравнивания зубов не травмируют дёсна и губы, не стирают зубную эмаль. Нет ограничений и по употребляемым продуктам и напиткам. Часто именно отсутствие строгих ограничений по использованию мотивирует пациентов выбирать лечение элайнерами, а не брекетами. Элайнеры — это отличная альтернатива брекет системам. Капы практически не уступают брекетам в эффективности и скорости лечения зубных аномалий и при этом лишены всех их недостатков.",
            )}
          </p>
        </div>
      </section>
      <Dialog fullWidth maxWidth="md" open={hash === "#how-to-use"} onClose={() => push({ hash: "" })}>
        <Button
          style={{ borderRadius: 0 }}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => push({ hash: "" })}
        >
          {_t("Закрыть")}
        </Button>
        <Player autoPlay playsInline>
          <BigPlayButton position="center" />
          <source src={howToUseAligners} />
        </Player>
      </Dialog>
    </>
  );
};
