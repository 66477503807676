import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleDialog } from "../../actions/dialogsAction";
import "./ContactsDialog.scss";
import { agentsList } from "../../agentsList.js";

export const ContactsDialog = () => {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();

  const contacts = useSelector(({ contacts }) => contacts);

  const close = () => dispatch(toggleDialog("contacts", false));

  return (
    <Dialog maxWidth="md" open={contacts.isShow} onClose={close} className="contactsDialog">
      <div className="contactsDialog__box">
        <Button variant="outlined" onClick={close}>
          {_t("Закрыть")}
        </Button>
        <div className="contactsDialog__logo">
          <img src={require("../../assets/img/logo_dark.png")} alt="" />
        </div>
        <h2>{_t("Представители компании TranspaDent:")}</h2>

        {agentsList(_t).map(({ id, location, name, tel }) => (
          <div className="contactsDialog__agent" key={id}>
            <h4>{location}:</h4>
            <h4>{name}</h4>
            <a href={`tel:${tel.replace(/ /g, "")}`}>{tel}</a>
          </div>
        ))}
      </div>
    </Dialog>
  );
};
