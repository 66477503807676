export const loadLink = (link, type) => {
  switch (type) {
    case "newTab":
      window.open(link, "_blank");
      break;
    case "download":
      window.location.assign(link);
      break;
    default:
  }
};
